// Generated by Framer (38f2e7f)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getLoadingLazyAtYPosition, Image, Link, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {zIopqgCvf: {hover: true, pressed: true}};

const cycleOrder = ["zIopqgCvf", "GuOaC2KKl"];

const serializationHash = "framer-I21fv"

const variantClassNames = {GuOaC2KKl: "framer-v-hqkh", zIopqgCvf: "framer-v-inrltn"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {Default: "zIopqgCvf", Mobile: "GuOaC2KKl"}

const getProps = ({height, id, tap, width, ...props}) => { return {...props, KM0Pp0A4y: tap ?? props.KM0Pp0A4y, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "zIopqgCvf"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;tap?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, KM0Pp0A4y, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "zIopqgCvf", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap1esg86o = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (KM0Pp0A4y) {const res = await KM0Pp0A4y(...args);
if (res === false) return false;}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={{webPageId: "augiA20Il"}} openInNewTab={false}><motion.a {...restProps} {...gestureHandlers} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-inrltn", className, classNames)} framer-gkoe9f`} data-framer-name={"Default"} data-highlight layoutDependency={layoutDependency} layoutId={"zIopqgCvf"} onTap={onTap1esg86o} ref={ref ?? ref1} style={{opacity: 1, ...style}} variants={{"zIopqgCvf-hover": {opacity: 0.8}, "zIopqgCvf-pressed": {opacity: 0.72}}} {...addPropertyOverrides({"zIopqgCvf-hover": {"data-framer-name": undefined}, "zIopqgCvf-pressed": {"data-framer-name": undefined}, GuOaC2KKl: {"data-framer-name": "Mobile"}}, baseVariant, gestureVariant)}><Image background={{alt: "", fit: "fill", intrinsicHeight: 251, intrinsicWidth: 300, loading: getLoadingLazyAtYPosition(((componentViewport?.y || 0) + (6 + ((((componentViewport?.height || 29) - 12) - 69) / 2)))), pixelHeight: 251, pixelWidth: 300, src: "https://framerusercontent.com/images/8EQy78xkuuEZQs3u8xQJcVXvcXo.png"}} className={"framer-1f30snt"} data-framer-name={"JJ_Logo_300x251"} layoutDependency={layoutDependency} layoutId={"Nvy97YoG3"} {...addPropertyOverrides({GuOaC2KKl: {background: {alt: "", fit: "fill", intrinsicHeight: 251, intrinsicWidth: 300, loading: getLoadingLazyAtYPosition(((componentViewport?.y || 0) + (0 + ((((componentViewport?.height || 20) - 3) - 17) / 2)))), pixelHeight: 251, pixelWidth: 300, src: "https://framerusercontent.com/images/8EQy78xkuuEZQs3u8xQJcVXvcXo.png"}}}, baseVariant, gestureVariant)}/><motion.div className={"framer-1v57itk"} layoutDependency={layoutDependency} layoutId={"Nz5OzktqR"}/></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-I21fv.framer-gkoe9f, .framer-I21fv .framer-gkoe9f { display: block; }", ".framer-I21fv.framer-inrltn { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 12px; height: min-content; justify-content: flex-start; overflow: visible; padding: 6px 0px 6px 12px; position: relative; text-decoration: none; width: 208px; }", ".framer-I21fv .framer-1f30snt { aspect-ratio: 1.1952191235059761 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 17px); overflow: visible; position: relative; width: 11%; }", ".framer-I21fv .framer-1v57itk { align-content: center; align-items: center; display: flex; flex: 1 0 0px; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: flex-start; min-height: 16px; overflow: visible; padding: 0px; position: relative; width: 1px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-I21fv.framer-inrltn, .framer-I21fv .framer-1v57itk { gap: 0px; } .framer-I21fv.framer-inrltn > * { margin: 0px; margin-left: calc(12px / 2); margin-right: calc(12px / 2); } .framer-I21fv.framer-inrltn > :first-child, .framer-I21fv .framer-1v57itk > :first-child { margin-left: 0px; } .framer-I21fv.framer-inrltn > :last-child, .framer-I21fv .framer-1v57itk > :last-child { margin-right: 0px; } .framer-I21fv .framer-1v57itk > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } }", ".framer-I21fv.framer-v-hqkh.framer-inrltn { justify-content: center; padding: 0px 0px 3px 12px; }", ".framer-I21fv.framer-v-hqkh .framer-1f30snt { width: 10%; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 29
 * @framerIntrinsicWidth 208
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]},"GuOaC2KKl":{"layout":["fixed","auto"]},"ZBoI51VoU":{"layout":["fixed","auto"]},"OSvGYbzcD":{"layout":["fixed","auto"]}}}
 * @framerVariables {"KM0Pp0A4y":"tap"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramermJRQLypHE: React.ComponentType<Props> = withCSS(Component, css, "framer-I21fv") as typeof Component;
export default FramermJRQLypHE;

FramermJRQLypHE.displayName = "Component/Brand Copy";

FramermJRQLypHE.defaultProps = {height: 29, width: 208};

addPropertyControls(FramermJRQLypHE, {variant: {options: ["zIopqgCvf", "GuOaC2KKl"], optionTitles: ["Default", "Mobile"], title: "Variant", type: ControlType.Enum}, KM0Pp0A4y: {title: "Tap", type: ControlType.EventHandler}} as any)

addFonts(FramermJRQLypHE, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})